<template>
  <div>
  <v-data-table
    :headers="headers"
    :items="payefiles"
    sort-by="payee_desc"
    class="elevation-1"
     dense
    :search="search"
		:expanded.sync="expanded"
		show-expand
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Payee File Maintenance</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field> 
		<vue-excel-xlsx
			:data="payefiles"
			:columns="columnHeaders"
			:file-name="module_name"
			>
			<v-btn icon alt class="mt-2" :color="payefiles.length=== 0 ? 'gray' : 'primary'" :disabled="payefiles.length===0"><v-icon>mdi-arrow-down-bold-circle</v-icon> </v-btn>
		</vue-excel-xlsx>
      </v-toolbar>
    </template>
		<template v-slot:expanded-item="{ item }">
				<td :colspan="headers.length">
					<v-simple-table>
						<template v-slot:default>
							<thead v-if="item.payee_banks.length != 0">
								<tr>
									<th>Payee Banks</th>
									<th v-if="item.paye_type !== 'BUSINESS PARTNER' && item.paye_type !== 'BRANCH'">
										Action
									</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="payee_bank in item.payee_banks" :key="payee_bank.payee_account_id">
									<td>{{payee_bank.item_data}}</td>
									<td v-if="item.paye_type !== 'BUSINESS PARTNER' && item.paye_type !== 'BRANCH'">
										<v-icon color="primary" small class="mr-2" @click="editPayeeBank(item, payee_bank)">edit</v-icon>
										<v-icon color="red" small @click="deletePayeeBank(item, payee_bank)">delete</v-icon>
									</td>
								</tr>
								<tr v-if="item.payee_banks.length == 0">No Payee Bank for this Payee</tr>
								<tr v-if="item.paye_type !== 'BUSINESS PARTNER' && item.paye_type !== 'BRANCH'">
									<v-btn outlined color="primary" @click="addPayeeBank(item)"><v-icon dark>mdi-plus</v-icon>Add Payee Bank</v-btn>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</td>
		</template>
		<template v-slot:item.action="{ item }">
					<v-icon
					color="grey"
					class="mr-2"
					small
					v-if="role_access < 3"
				>
					mdi-pencil-off
				</v-icon>
				<v-icon
					color="primary"
					v-else
					class="mr-2"
					small
					@click="editItem(item)"
				>
					edit
				</v-icon>
					<v-icon
					color="grey"
					class="mr-2"
					small
					v-if="role_access < 4"
				>
					mdi-delete-off
				</v-icon>
				<v-icon
					small
					v-else
					color="red"
					@click="deleteItem(item)"
				>
					delete
				</v-icon>
			</template>
  </v-data-table>
  <create-payefile v-if="role_access > 1" ></create-payefile>
	<create-payeebank :modules="'payefile'" :id.sync="id"></create-payeebank>
  </div>
</template>

<script>
import createPayefile from './createPayefile.vue';
import createPayeeBank from '../payeebank/createPayeeBank.vue';
import { mapGetters } from 'vuex';

export default {
	components: {
		'create-payefile': createPayefile,
		'create-payeebank': createPayeeBank
	},
	data(){
		return{
			module_name: 'Payee',
			role_access: 1,
			id: 0,
			expanded: [],
			search: ''
		};
	},
	watch: {
		payefiles: {
			handler(val) {
				val.map(det => {
					det.payee_status = (det.status === 1 ? 'Active' : 'Inactive');
				});
			},
			deep: true
		}
	},
	computed: {
		...mapGetters({
			payefiles: 'payefile/payefiles',
			headers: 'payefile/headers',
			columnHeaders: 'payefile/columnHeaders',
			editedItem: 'payefile/editedItem',
			defaultItem: 'payefile/defaultItem',
			dialog: 'payefile/dialog',
			editedIndex: 'payefile/editedIndex',
			valid: 'payefile/valid',
			currUser: 'auth/currUser'
		}),
	},
	mounted () {
		this.$store.dispatch('payefile/getPayefiles');

		let roles = this.currUser.roleaccess;
		if(roles.length > 0){
			let roleaccess = roles.filter(r => r.module_name === this.module_name);
			if(roleaccess.length > 0){
				this.role_access = roleaccess[0].access_level;
			}
		}
	},
	methods: {
		editItem (item) {
			this.$store.dispatch('payefile/setEditedIndex',this.payefiles.indexOf(item));
			//this.$store.dispatch('payefile/setEditedItems',Object.assign({}, item));
			this.$store.dispatch('payefile/getPayeeDetails', item.id);
			this.$store.dispatch('payefile/setDialog',true);
			this.$store.dispatch('payefile/setValid',true);
			this.$store.dispatch('payefile/setformTitle','Edit Entry');
		},
		deleteItem (item) {
			this.$swal.fire({
				title: 'Delete ' + item.payee_code + '?',
				text: 'You won\'t be able to revert this!',
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.value) {
					this.$store.dispatch('payefile/deletePayefile',item);
				}
			});
		},
		addPayeeBank(item){
			let payload = {
				payee_id: item.id,
				bank_id: '',
				bank_branch: '',
				account_number: ''
			};
			//this.id = item.id;
			this.$store.dispatch('payefile/getPayeeDetails', item.id);
			this.$store.dispatch('payefile/setEditedIndex',this.payefiles.indexOf(item));
			this.$store.commit('payefile/SET_EDITEDITEM_PAYEEBANK', payload);
			this.$store.commit('payefile/SET_PAYEEBANK_DIALOG', true);
			this.$store.dispatch('payefile/setformTitle','New Entry');
		},
		editPayeeBank(item, payeebank){
			//this.id = item.id;
			this.$store.dispatch('payefile/getPayeeDetails', item.id);
			this.$store.dispatch('payefile/setEditedIndex',this.payefiles.indexOf(item));
			this.$store.dispatch('payefile/getPayeeDetails', payeebank.payee_id);
			this.$store.commit('payefile/SET_EDITEDITEM_PAYEEBANK', payeebank);
			this.$store.commit('payefile/SET_PAYEEBANK_DIALOG', true);
			this.$store.dispatch('payefile/setformTitle','Edit Entry');
		},
		async deletePayeeBank(item, payeebank){
			await this.$store.dispatch('payefile/getPayeeDetails', item.id);
			this.$store.dispatch('payefile/setEditedIndex',this.payefiles.indexOf(item));
			let deleted = [];
			deleted.push({payee_account_id: payeebank.payee_account_id});
			this.$swal.fire({
				title: 'Delete ' + payeebank.item_data + '?',
				text: 'You won\'t be able to revert this!',
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.value) {
					this.editedItem.edit_by = this.currUser.id;
					this.editedItem.deleted = deleted;
					this.editedItem.payee_banks = [];
					this.editedItem.updated = [];
					delete this.editedItem['paye_type'];
					delete this.editedItem['updated_at'];
					delete this.editedItem['created_at'];
					this.$store.dispatch('payefile/updatePayefile',this.editedItem);
				}
			});
		},
		
		download () {
		// Download
		},
		changeNextData() {
			var nextPage = this.payefiles.current_page + 1;
			this.$store.dispatch('payefile/getDataPages', nextPage);
		},

		changePrevData() {
			var prevPage = this.payefiles.current_page - 1;
			this.$store.dispatch('payefile/getDataPages', prevPage);
		}
	}
};
</script>
