<template>
    <v-dialog v-model="dialog" persistent max-width="50%" scrollable>
      <v-form
        ref="form"
        v-model="isvalid"
        lazy-validation
      >
        <v-card tile>
          <v-toolbar
            flat
            dark
            color="primary"
          >
            <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
            <div class="flex-grow-1"></div>
            <v-btn
              icon
              dark
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md6>
                  <v-autocomplete
										v-model="editedItem.bank_id"
										:items="data"
										editable
										hide-details
										item-text="bank_name"
                    label="Bank"
										item-value="id"
										:rules="bank_rules"
									></v-autocomplete>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field 
                      v-model="editedItem.bank_branch"
                      :counter="250"
                      label="Bank Branch"
                      required
                      maxlength=250
                      :rules="bankBranchRules"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field 
                      v-model="editedItem.payee_bank_name"
                      :counter="250"
                      label="Payee Bank Name"
                      required
                      maxlength=250
                      :rules="payeeBankRules"
                  ></v-text-field>
                </v-flex>                <v-flex xs12 sm6 md6>
                  <v-text-field 
                      v-model="editedItem.account_number"
											:v-mask="acct_number"
                      :counter="25"
                      label="Account Number"
                      required
                      maxlength=25
                      :rules="accountNumberRules"
                  ></v-text-field>
                </v-flex>
								<v-flex xs12 sm6 md6>
									<v-select
										v-model="editedItem.currency"
                      label="Currency"
										:items="currency"
										data-vv-name="empStatus"
									></v-select>
								</v-flex>
          </v-layout>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" dark @click="save">Save</v-btn>
          <v-btn color="red darken-1" text @click="close">Cancel</v-btn>
        </v-card-actions>
      
      </v-card>
    </v-form>
  </v-dialog>
        
</template>

<script>
import { mapGetters } from 'vuex';
import { mask } from 'vue-the-mask';

export default {
	props: {
		modules: {
			type: String
		},
		id: {
			type: Number
		},
	},
	directives: {
		mask,
	},
	data: () => ({
		acct_number: '###############',
		search: null,
		isvalid: false,
		bank_rules: [
			v => !!v || 'Bank is required',
		],
		bankBranchRules: [
			v => !!v || 'Bank Branch is required'
		],
		accountNumberRules: [
			v => !!v || 'Account Number is required',
			v => (v && v.length < 16) || 'Account Number must be 15 characters or less',
		],
		payeeBankRules: [
			v => !!v || 'Payee Bank Name is required'
		],
		data: [],
		nextPage: ''
	}),
	mounted() {
		this.$store.dispatch('bankheader/getBankheaders');
	},
	computed: {
		...mapGetters({
			item: 'payefile/editedItem',
			itemBranch: 'branch/editedItem',
			itemBusiPart: 'businesspartner/editedItem',
			editedItem: 'payefile/payeeBank',
			dialog: 'payefile/payeeBankDialog',
			formTitle: 'payefile/formTitle',
			valid: 'payefile/valid',
			banks: 'bankheader/bankheaders',
			currUser: 'auth/currUser',
			currency: 'payefile/currency'
		})
	}, 
	watch: {
		banks: {
			handler() {
				this.getData();
			}
		},
		editedItem: {
			handler() {
				if(this.data.length == 0 ){
					this.getData();
				}
			}
		}
	},
	methods: {
		async getData(){
			this.data = this.banks.data;
			this.nextPage = this.banks.next_page_url;
			let page = 2;

			while (this.nextPage !== null && page !== this.banks.current_page){
				await this.$store.dispatch('bankheader/getData', page).then(response => {
					this.nextPage = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.data.push(details);
					});
				});
				page ++;
			}
		},
		close () {
			setTimeout(() => {
				// this.$refs.form.reset();
				// this.$refs.form.resetValidation();
				this.$store.commit('payefile/SET_PAYEEBANK_DIALOG', false);
				this.isvalid = false;
			}, 300);
		},
		save() {
			let item = {};
			this.$store.dispatch('payefile/setValid',this.$refs.form.validate());
			this.isvalid = this.$refs.form.validate();
			//item.id = this.id;
			if(this.modules == 'payefile'){
				item = this.item;
			}else if(this.modules == 'branch'){
				item = this.itemBranch;
			}else if(this.modules == 'business_partners'){
				item =this.itemBusiPart;
			}
			item.payee_banks = [];
			item.deleted = [];
			item.updated = [];
			this.editedItem.bank_address = this.editedItem.bank_branch;
			this.editedItem.status = 1;
			this.editedItem.edit_by = this.currUser.id;
			if(this.editedItem.payee_account_id === item.id || this.editedItem.payee_account_id === '' || this.editedItem.payee_account_id === undefined){
				item.payee_banks.push(this.editedItem);
			}else{
				item.updated.push(this.editedItem);
			}
			delete this.editedItem['item_data'];
			if(this.valid){			
				delete item['updated_at'];
				delete item['created_at'];
				if(this.modules == 'payefile'){
					this.$store.dispatch('payefile/updatePayefile',item);
				}else if(this.modules == 'branch'){
					this.$store.dispatch('branch/updateBranch', item);
				}else if(this.modules == 'business_partners'){
					this.$store.dispatch('businesspartner/updateBusinesspartner', item);
				}
			}
		}
	}
};
</script>