<template>
  <div>
    <v-btn right dark color="primary" @click="addPayeeBank">
      <v-icon>mdi-plus</v-icon>
      <span>Add Payee Bank</span>
    </v-btn>
    <v-simple-table dense v-if="details.length !== 0">
      <template v-slot:default>
        <thead>
          <tr>
            <th>#</th>
            <th>Bank</th>
            <th>Branch</th>
            <th>Payee Bank Name</th>
            <th>Account Number</th>
            <th>Currency</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(detail, detail_key) in details"
            :key="detail.detail_key"
          >
            <td>{{ detail_key + 1 }}</td>
            <td >
              <v-autocomplete
										v-model="detail.bank_id"
										:items="banks"
										editable
										item-text="bank_name"
										item-value="id"
							></v-autocomplete>
            </td>
            <td>
              <v-text-field 
                  v-model="detail.bank_branch"
                  :counter="250"
                  required
                  maxlength=250
              ></v-text-field>
            </td>
            <td>
              <v-text-field 
                  v-model="detail.payee_bank_name"
                  :counter="250"
                  required
                  maxlength=250
              ></v-text-field>
            </td>
            <td>
              <v-text-field 
                  v-model="detail.account_number"
                  :counter="25"
                  required
                  maxlength=25
              ></v-text-field>
            </td>
            <td width="15%">
              <v-select
                v-model="detail.currency"
                :items="currency"
                data-vv-name="empStatus"
              ></v-select>
            </td>
            <td>
              <v-btn icon>
                <v-icon small color="red" @click="deletePayeeBank(detail_key, detail)">delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
	props: {
		details: {
			type: Array
		},
		deleted: {
			type: Array
		},
		banks: {
			type: Array
		}
	},
	computed: {
		...mapGetters({
			currency: 'payefile/currency'
		}),
	},
	methods: {
		addPayeeBank() {
			const newPayeeBank = {payee_account_id: '', payee_bank_name: '', account_number: '', bank_id: '', bank_branch: ''};
			this.details.push(newPayeeBank);
		},
		deletePayeeBank(key, detail) {
			this.details.filter((detail, index) => {
				if (index === key) {
					this.details.splice(key, 1);
				}
			});
      
			if(detail.payee_account_id){
				this.deleted.push({payee_account_id: detail.payee_account_id});
			}
		}
	}
};

</script>
