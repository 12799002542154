<template>
  <!-- <v-dialog v-model="dialog" persistent fullscreen> -->
  <v-dialog v-model="dialog" persistent max-width="75%" scrollable>
    <template v-slot:activator="{ on }">
      <app-add-button v-bind:module="'payefile'"></app-add-button>
    </template>
    <v-form ref="form" v-model="isvalid" lazy-validation>
      <v-card title>
        <v-toolbar flat dark color="primary">
          <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
          <div class="flex-grow-1"></div>
          <v-btn icon dark @click="close">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex>
                <span class="title blue--text text-darken-2"
                  >Payee Information</span
                >
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs12 sm6 md4>
                <v-text-field
                  v-model="editedItem.payee_desc"
                  :counter="250"
                  :rules="payee_desc_Rules"
                  label="Payee Name"
                  required
                  maxlength="250"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-select
                  v-model="editedItem.payee_type"
                  :items="payeeType"
                  :rules="[(v) => !!v || 'Please select Payee Type']"
                  label="Payee Type"
                  data-vv-name="payeeType"
                  required
                  @change="payee_type_change(editedItem.payee_type)"
                >
                </v-select>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-text-field
                  v-model="editedItem.cctr_code"
                  :counter="5"
                  label="Default CCTR Code"
                  required
                  maxlength="5"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-select
                  v-model="editedItem.dept_code"
                  :items="dataDepts"
                  data-vv-name="dataDepts"
                  item-text="dept_desc"
                  item-value="dept_code"
                  label="Department Code"
                  :rules="defaultRules"
                  required
                  @change="getData"
                >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.dept_code }} - {{ data.item.dept_desc }}
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.dept_code }} - {{ data.item.dept_desc }}
                  </template>
                </v-select>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-select
                  v-model="editedItem.brnc_code"
                  :items="branchesItem"
                  data-vv-name="branchesItem"
                  item-text="brnc_name"
                  item-value="brnc_code"
                  label="Branch Code"
                  :rules="defaultRules"
                  required
                  v-on:focus="getData"
                >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.brnc_code }} - {{ data.item.brnc_name }}
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.brnc_code }} - {{ data.item.brnc_name }}
                  </template>
                </v-select>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-select
                  v-model="editedItem.section_code"
                  :items="sectionItem"
                  data-vv-name="sections"
                  item-text="section_name"
                  item-value="section_code"
                  label="Section Code"
                  :rules="!hasSection ? [] : [(v) => !!v || 'Required']"
                  required
                >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.section_code }} - {{ data.item.section_name }}
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.section_code }} - {{ data.item.section_name }}
                  </template>
                </v-select>
              </v-flex>

              <v-flex xs12 sm6 md4>
                <v-select
                  v-model="editedItem.status"
                  :items="activeStatus"
                  :rules="[(v) => (!!v && v !== 0) || 'Please select Status']"
                  label="Status"
                  data-vv-name="activeStatus"
                  required
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-text-field
                  v-model="editedItem.business_style"
                  label="Business Style"
                  :rules="defaultRules"
                  required
                  :counter="50"
                  maxlength="50"
                >
                </v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex md12><h3>Other Type</h3></v-flex>
              <v-flex v-for="(detail, key) in otherType" :key="key">
                <v-checkbox
                  v-model="editedItem.other_type"
                  :label="detail.text"
                  :value="detail.value"
                ></v-checkbox>
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <!-- EDITED -->
              <v-flex md12>
                <span class="title blue--text text-darken-2">Address</span>
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex>
                <v-checkbox
                  v-model="int_address"
                  label="International Address"
                  false-value="0"
                  true-value="1"
                ></v-checkbox>
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs12 sm6 md4>
                <v-text-field
                  v-model="editedItem.house_unit"
                  :counter="30"
                  label="House Unit"
                  :rules="defaultRules"
                  required
                  maxlength="30"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-text-field
                  v-model="editedItem.street"
                  :rules="defaultRules"
                  :counter="50"
                  label="Street"
                  required
                  maxlength="50"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-autocomplete
                  v-if="brgyData.length !== 0 && int_address === '0'"
                  v-model="editedItem.barangay"
                  :items="brgyData"
                  label="Barangay"
                  item-text="brpcb_id_name"
                  item-value="brpcb_id_name"
                  @change="getBrgyDetails()"
                  :rules="defaultRules"
                  required
                >
                </v-autocomplete>
                <v-text-field
                  v-model="editedItem.barangay"
                  :rules="defaultRules"
                  :counter="50"
                  label="Barangay"
                  required
                  v-else
                  maxlength="50"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-autocomplete
                  v-if="int_address === '0'"
                  v-model="editedItem.municipality"
                  :items="municipalityData"
                  label="Municipality/City"
                  item-text="name"
                  item-value="name"
                  @change="getDetails()"
                  :rules="defaultRules"
                  required
                >
                </v-autocomplete>
                <v-text-field
                  v-else
                  v-model="editedItem.municipality"
                  :rules="defaultRules"
                  :counter="50"
                  label="Municipality/City"
                  required
                  maxlength="50"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-autocomplete
                  v-if="int_address === '0'"
                  v-model="editedItem.region"
                  :items="regionData"
                  label="Region"
                  item-text="name"
                  item-value="name"
                  @change="getRegionDetails()"
                  :rules="defaultRules"
                  required>
                </v-autocomplete>
                <v-text-field
                  v-model="editedItem.region"
                  :rules="defaultRules"
                  :counter="50"
                  label="Region"
                  v-else
                  required
                  maxlength="50">
                </v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-text-field
                  v-model="editedItem.zip_code"
                  :rules="defaultRules"
                  :counter="4"
                  label="Zip Code"
                  required
                  :readonly="int_address=='0'"
                  maxlength="4"
                >
                  <!-- <template v-slot:append v-if=" int_address === '0'">        
                      <v-icon @click="getAddress(editedItem.zip_code, 'zipcode')" :color="hrisToken ? 'primary' : 'gray'" :disabled="!hrisToken"> mdi-magnify </v-icon> 
                    </template> -->
                </v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <!-- EDITED -->
              <v-flex md12>
                <span class="title blue--text text-darken-2"
                  >Personal Information</span
                >
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs12 sm6 md4>
                <v-text-field
                  v-model="editedItem.contact_num"
                  :counter="15"
                  label="Contact No."
                  :rules="defaultRules"
                  required
                  maxlength="15"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-text-field
                  v-model="editedItem.fax"
                  :counter="12"
                  label="Fax"
                  required
                  maxlength="12"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-text-field
                  v-model="editedItem.email_add"
                  :counter="50"
                  label="Email Address"
                  required
                  maxlength="50"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-text-field
                  v-model="editedItem.tin"
                  :counter="15"
                  label="TIN"
                  :rules="defaultRules"
                  required
                  maxlength="15"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-select
                  v-model="editedItem.business_type"
                  :items="businessType"
                  :rules="[(v) => !!v || 'Please select Business Type']"
                  label="Business Type"
                  data-vv-name="businessType"
                  required
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-select
                  v-model="editedItem.vat"
                  :items="vatType"
                  :rules="[(v) => !!v || 'Please select VAT Type']"
                  label="VAT Type"
                  data-vv-name="vatType"
                  required
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md4 v-if="editedItem.vat !== '0'">
                <v-select
                  v-model="editedItem.atc_code"
                  :items="dataAtc"
                  data-vv-name="alphataxcodes"
                  :rules="[(v) => !!v || 'Please select Alpha Tax Code']"
                  item-value="atc_code"
                  label="Alpha Tax Code"
                  required
                >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.atc_code }} - {{ data.item.atc_desc }} (
                    {{ data.item.atc_rate }}% )
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.atc_code }} - {{ data.item.atc_desc }} (
                    {{ data.item.atc_rate }}% )
                  </template>
                </v-select>
              </v-flex>
              <v-flex xs12 sm6 md4 v-if="editedItem.vat !== '0'">
                <v-text-field
                  v-model="editedItem.tax_percent"
                  label="Withholding Tax Percentage"
                  :rules="defaultRules"
                  required
                  type="number"
                >
                </v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap v-if="editedItem.payee_type == '5'">
              <!-- EDITED -->
              <v-flex md12>
                <span class="title blue--text text-darken-2"
                  >Employee Information</span
                >
              </v-flex>
            </v-layout>
            <v-layout wrap v-if="editedItem.payee_type == '5'">
              <v-flex xs12 sm6 md4>
                <v-text-field
                  v-model="editedItem.emp_idno"
                  :counter="8"
                  label="Employee IDNo."
                  :rules="defaultRules"
                  required
                  maxlength="8"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-text-field
                  v-model="editedItem.sss_no"
                  :counter="15"
                  label="SSS No."
                  :rules="defaultRules"
                  required
                  maxlength="15"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-text-field
                  v-model="editedItem.philhealth_no"
                  :counter="15"
                  label="Philhealth No."
                  :rules="defaultRules"
                  required
                  maxlength="15"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-text-field
                  v-model="editedItem.hdmf_no"
                  :counter="15"
                  label="HDMF No."
                  :rules="defaultRules"
                  required
                  maxlength="15"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-text-field
                  v-model="editedItem.position"
                  :counter="50"
                  label="Position"
                  :rules="defaultRules"
                  required
                  maxlength="50"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-select
                  v-model="editedItem.emp_status"
                  :items="empStatus"
                  :rules="[(v) => !!v || 'Please select Employee Status']"
                  label="Employee Status"
                  data-vv-name="empStatus"
                  required
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout wrap v-if="editedItem.payee_type == '4'">
              <v-flex md12>
                <span class="title blue--text text-darken-2"
                  >Forex/WUBS Client</span
                >
              </v-flex>
            </v-layout>
            <v-layout wrap v-if="editedItem.payee_type == '4'">
              <v-flex xs12 sm6 md4>
                <v-text-field
                  v-model="editedItem.trader_name"
                  :rules="defaultRules"
                  :counter="50"
                  label="Trader Name"
                  required
                  maxlength="50"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap v-if="editedItem.payee_type == '2'">
              <!-- EDITED -->
              <v-flex md12>
                <span class="title blue--text text-darken-2"
                  >Business Partner Information</span
                >
              </v-flex>
            </v-layout>
            <v-layout wrap v-if="editedItem.payee_type == '2'">
              <v-flex xs12 sm6 md4>
                <v-select
                  v-model="editedItem.class_code"
                  :items="classCode"
                  :rules="[(v) => !!v || 'Please select Class Code']"
                  label="Class Code"
                  data-vv-name="classCode"
                  required
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-select
                  v-model="editedItem.sub_agent_status"
                  :items="subagentStatus"
                  :rules="[(v) => !!v || 'Please select Sub-Agent Status']"
                  label="Sub-Agent Status"
                  data-vv-name="subagentStatus"
                  required
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <!-- EDITED -->
              <v-flex md12>
                <span class="title blue--text text-darken-2">Payee Banks</span>
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex md12>
                <add-payee-bank
                  :details.sync="editedItem.payee_banks"
                  :deleted.sync="deleted"
                  :banks.sync="data"
                ></add-payee-bank>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" dark @click="save">Save</v-btn>
          <v-btn color="red darken-1" text @click="close">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

    <v-snackbar v-model="snackbar" right>
      {{ error_message }}
      <v-btn color="pink" text @click="snackbar = false"> Close </v-btn>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import AppAddButtonVue from '../../../partials/AppAddButton.vue';
import AddPayeeBanks from '../payeebank/addPayeeBank.vue';

export default {
	components: {
		'app-add-button': AppAddButtonVue,
		'add-payee-bank': AddPayeeBanks,
	},
	data: () => ({
		data: [],
		nextPage: '',
		search: null,
		deleted: [],
		updated: [],
		selected: [],
		hasSection: false,
		int_address: '0',
		snackbar: false,
		error_message: '',
		//EDITED
		payeeTypeSelected: '',
		isvalid: true,
		// payee_codeRules: [
		// 	v => !!v || 'Payee Code is required.',
		// ],
		payee_desc_Rules: [
			(v) => !!v || 'Payee Name',
			(v) =>
				(v && v.length <= 250) || 'Payee Name must be less than 250 characters',
		],
		payee_typeRules: [
			(v) => !!v || 'Payee Type',
			(v) =>
				(v && v.length <= 1) || 'Payee type must be less than 2 characters',
		],
		vat_typeRules: [
			(v) => !!v || 'Type of VAT',
			(v) =>
				(v && v.length <= 2) || 'Type of VAT must be less than 3 characters',
		],
		business_typeRules: [
			(v) => !!v || 'Business Type',
			(v) =>
				(v && v.length <= 2) || 'Business type must be less than 3 characters',
		],

		emp_statusRules: [
			(v) => !!v || 'Employee Status',
			(v) =>
				(v && v.length <= 1) ||
        'Employee Status must be less than 2 characters',
		],

		class_codeRules: [
			(v) => !!v || 'Class Code',
			(v) =>
				(v && v.length <= 1) || 'Class Code must be less than 2 characters',
		],
		sub_agent_statusRules: [
			(v) => !!v || 'Sub-Agent Status',
			(v) =>
				(v && v.length <= 1) ||
        'Sub-Agent Status must be less than 2 characters',
		],
		defaultRules: [(v) => !!v || 'Required'],
		branchesItem: [],
		sectionItem: [],
		hasError: false,
		nextPageDepts: '',
		nextPageBranch: '',
		nextPageAtc: '',
		dataAtc: [],
		dataDepts: [],
		dataBranch: [],
		activeStatus: [
			{ value: '1', text: 'Active' },
			{ value: '0', text: 'Inactive' },
		],
		brgyData: [],
		municipalityData: [],
		regionData: [],
	}),
	mounted() {
		this.$store.dispatch('bankheader/getBankheaders');
		this.$store.dispatch('dept/getDepts');
		this.$store.dispatch('branch/getBranches');
		this.$store.dispatch('section/getSections');
		this.$store.dispatch('alphataxcode/getAlphataxcodeDropdown');
		this.deleted = [];
		this.details = [];
		this.brgyData = [];
		this.municipalityData = [];
		this.regionData = [];
		if (!this.editedIndex || this.editedIndex > -1) {
			Object.keys(this.data.form).forEach(function (key) {
				self.data.form[key] = '';
			});
		}
		this.getMunicipality();
		this.getRegion();
	},
	computed: {
		...mapGetters({
			payefiles: 'payefile/payefiles',
			payeCode: 'payefile/payeCode',
			payedesc: 'payefile/payeDesc',
			payeeType: 'payefile/payeeType',
			depts: 'dept/depts',
			branches: 'branch/branches',
			sections: 'section/sections',
			alphataxcodes: 'alphataxcode/alphataxcodes',
			vatType: 'payefile/vatType',
			businessType: 'payefile/businessType',
			empStatus: 'payefile/empStatus',
			classCode: 'payefile/classCode',
			subagentStatus: 'payefile/subagentStatus',
			defaultItem: 'payefile/defaultItem',
			dialog: 'payefile/dialog',
			editedIndex: 'payefile/editedIndex',
			formTitle: 'payefile/formTitle',
			valid: 'payefile/valid',
			isLoading: 'app/isLoading',
			editedItem: 'payefile/editedItem',
			currUser: 'auth/currUser',
			banks: 'bankheader/bankheaders',
			otherType: 'payefile/otherType',
			hrisToken: 'auth/hrisToken',
		}),
	},
	watch: {
		alphataxcodes: {
			handler() {
				this.getDataAtc();
			},
		},
		depts: {
			handler() {
				this.getDataDepts();
			},
		},
		banks: {
			handler() {
				this.getDataBanks();
			},
		},
		editedItem: {
			handler(val) {
				if (val.status) {
					val.status = val.status.toString();
				}
			},
			deep: true,
		},
	},
	methods: {
		payee_type_change(item) {
			this.payeeTypeSelected = item;
		},
		close() {
			setTimeout(() => {
				if (!this.isLoading) {
					this.$refs.form.reset();
					this.$refs.form.resetValidation();
					this.$store.dispatch('payefile/setValid', true);
					this.$store.dispatch('payefile/setDialog', false);
					this.$store.dispatch('payefile/setEditedIndex', '-1');
					this.isvalid = false;
					this.deleted = [];
					this.details = [];
				}
			}, 2000);
		},
		async getDataDepts() {
			this.dataDepts = this.depts.data;
			this.nextPageDepts = this.depts.next_page_url;
			let page = 2;

			while (this.nextPageDepts !== null) {
				await this.$store.dispatch('dept/getData', page).then((response) => {
					this.nextPageDepts = response.data.data.next_page_url;
					response.data.data.data.forEach((details) => {
						this.dataDepts.push(details);
					});
				});
				page++;
			}
			let dept = this.dataDepts.filter((e) => e.dept_status === 1);
			this.dataDepts = dept;
		},
		async getDataAtc() {
			this.dataAtc = this.alphataxcodes.data;
			this.nextPageAtc = this.alphataxcodes.next_page_url;
			let page = 2;

			while (this.nextPageAtc !== null) {
				await this.$store
					.dispatch('alphataxcode/getData', page)
					.then((response) => {
						this.nextPageAtc = response.data.data.next_page_url;
						response.data.data.data.forEach((details) => {
							this.dataAtc.push(details);
						});
					});
				page++;
			}
		},
		async getDataBanks() {
			this.data = this.banks.data;
			this.nextPage = this.banks.next_page_url;
			let page = 2;

			while (this.nextPage !== null && page !== this.banks.current_page) {
				await this.$store
					.dispatch('bankheader/getData', page)
					.then((response) => {
						this.nextPage = response.data.data.next_page_url;
						response.data.data.data.forEach((details) => {
							this.data.push(details);
						});
					});
				page++;
			}
		},
		getData() {
			this.hasSection = false;
			this.sectionItem = [];
			let dept = this.dataDepts.filter(
				(det) => det.dept_code == this.editedItem.dept_code
			);
			let dept_id = dept[0].id;
			if (dept_id == 1 || dept_id == 25 || dept_id == 26 || dept_id == 29) {
				this.branchesItem = this.branches.filter((e) => e.brnc_stat === 'OPEN');
			} else {
				this.branchesItem = this.branches.filter(
					(det) => det.brnc_code === 'HOB' && det.brnc_stat === 'OPEN'
				);
			}
			if (dept[0].sections.length > 0) {
				this.sectionItem = dept[0].sections;
				this.hasSection = true;
			}
		},
		save() {
			this.hasError = false;
			this.$store.dispatch('payefile/setValid', this.$refs.form.validate());
			this.isvalid = this.$refs.form.validate();
			if (this.isvalid) {
				let updated = [];
				let payee_bank = [];
				if (this.editedItem.payee_banks) {
					this.editedItem.payee_banks.map((details) => {
						if (
							!details.currency ||
              !details.account_number ||
              !details.bank_id ||
              !details.bank_branch
						) {
							this.hasError = true;
						}
					});
				}
				this.editedItem.payee_banks.map((details) => {
					details.edit_by = this.currUser.id;
					details.bank_address = details.bank_branch;
					details.status = 1;
					if (details.payee_account_id != '') {
						updated.push(details);
					} else {
						payee_bank.push(details);
					}
				});

				delete this.editedItem['updated_at'];
				delete this.editedItem['created_at'];
				this.editedItem.updated = updated;
				this.editedItem.deleted = this.deleted;
				this.editedItem.payee_banks = payee_bank;
				this.editedItem.city = this.editedItem.municipality;
				if (!this.hasError) {
					if (this.editedIndex > -1) {
						this.$store.dispatch('payefile/updatePayefile', this.editedItem);
					} else {
						this.$store.dispatch('payefile/savePayefile', this.editedItem);
					}
					setTimeout(() => {
						this.close();
					}, 3000);
				} else {
					this.snackbar = true;
					this.error_message = 'Please complete the details for payee bank.';
				}
			} else {
				this.$store.commit(
					'app/SET_SNACK_BAR',
					{
						status: true,
						message: 'Please fill out all the required fields.',
						messagetype: 'error',
					},
					{
						root: true,
					}
				);
			}
		},
		getMunicipality() {
			let payload = {
				url: 'backend.region-province-city-list',
				data: ''
			};
			this.$store
				.dispatch('payefile/getAddress', {
					data: payload,
					token: this.hrisToken,
				})
				.then((response) => {
					this.municipalityData = response.data.body;
				});
		},
		getRegion() {
			let payload = {
				url: 'backend.region-list',
				data: ''
			};
			this.$store
				.dispatch('payefile/getAddress', {
					data: payload,
					token: this.hrisToken,
				})
				.then((response) => {
					this.regionData = response.data.body;
				});
		},
		getDetails() {
			this.editedItem.barangay = '';
			this.editedItem.zip_code = '';
			let payload = {
				url: 'backend.region-province-city-by-province',
				data: this.editedItem.municipality
			};
      
			this.$store
				.dispatch('payefile/getAddress', {
					data: payload,
					token: this.hrisToken,
				})
				.then((response) => {
					this.brgyData = [];
					let data = response.data.body;
					if(data.length > 1){
						this.brgyData = data;
					} else {
						if (data[0].zipcode) {
							this.editedItem.zip_code = data[0].zipcode;
							this.zipcode = true;
						}
						if (data[0].br_id_name) {
							this.editedItem.region = data[0].br_id_name;
						}
					}
				});
		},
		getRegionDetails() {
			this.editedItem.barangay = '';
			this.editedItem.zip_code = '';
			this.editedItem.municipality = '';
			let payload = {
				url: 'backend.region-by-name',
				data: this.editedItem.region
			};
			this.$store
				.dispatch('payefile/getAddress', {
					data: payload,
					token: this.hrisToken,
				})
				.then((response) => {
					this.municipalityData = [];
					response.data.body.map(det => {
						let data = {name: det.brpc_id_name};
						this.municipalityData.push(data);
					});
				});
		},
		getBrgyDetails(){
			let data = this.brgyData.filter(e => e.brpcb_id_name == this.editedItem.barangay); 
			if (data[0].zipcode) {
				this.editedItem.zip_code = data[0].zipcode;
				this.zipcode = true;
			}
		}
	},
};
</script>